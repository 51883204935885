
@media (max-width: 992px) {
    .lead-page-container{
        padding: 0 16px 0 16px !important;
        background-color: var(--gray-light);
    }
    .lead-details-title{
        padding-bottom: 12px !important;
    }
    .lead-details-text{
        padding-bottom: 0 !important;
    }
}

.lead-details-container{
    background-color: var(--gray-light) !important;
}

.lead-details-panel{
    /*min-height: calc(100vh - 211px);*/
    /*max-height: calc(100vh - 211px);*/
}

.lead-details, .lead-history-desktop {
    background-color: var(--white);
    padding: 20px;
    border-radius: 8px;
}
.lead-details-mobile-container{
    padding: 20px;
    border-radius: 8px;
    background-color: var(--gray-light) !important;
}
.lead-page-container {
    padding: 0 16px 0 16px !important;
    background-color: var(--gray-light);
}
.lead-details-title{
    font-size: 14px;
    font-weight: 400;
    color: var(--gray);
    padding-bottom: 20px;
}
.lead-details-text{
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 20px;
}
.border-end{
    border-right: 1px solid var(--gray) !important;
}
.lead-action-buttons{
    margin-top: 40px;
}