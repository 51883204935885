
.arrowChipContainer {
    display: flex;
}

.arrow-steps .step {
    font-size: var(--font-size-sm);
    text-align: center;
    color: var(--gray-dark);
    cursor: default;
    margin: 0 6px 6px 0;
    padding: 7px 10px 7px 16px;
    min-width: 130px !important;
    float: left;
    position: relative;
    background-color: var(--gray-light);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -16px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid var(--gray-light);
    z-index: 2;
    transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;
    z-index: 0;
}

.arrow-steps .step:first-child:before {
    border: none;
}

.arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
    position: relative;
}

.arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
    color: #fff;
    background-color: var(--gray-light);
}

.arrow-steps .step.current:after {
    border-left: 17px solid var(--gray-light);
}


.arrow-steps .step-error:after {
    border-left: 17px solid var(--tertiary) !important;
}
.arrow-steps .step-success:after {
    border-left: 17px solid var(--success-light) !important;
}
.arrow-steps .step-warning:after {
    border-left: 17px solid var(--tertiary) !important;
}
.arrow-steps .step-progress:after {
    border-left: 17px solid var(--blue-dark) !important;
}
.arrow-steps .step-none:after {
    border-left: 17px solid var(--blue-light) !important;
}

@media (max-width: 992px) {

    .arrow-steps .step {
        font-size: var(--font-size-sm);
        text-align: center;
        color: var(--gray-dark);
        cursor: default;
        margin: 0px 6px 6px 0px;
        padding: 7px 10px 7px 16px;
        min-width: 110px;
        float: left;
        position: relative;
        background-color: var(--gray-light);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: background-color 0.2s ease;
    }
}

.content-box{
    width:250px;
    min-width:250px;
    max-width:250px;
}

@media (max-width: 576px) {
    .content-box{
        width:100%;
        min-width:100%;
        max-width:100%;
    }
}


.template-data{
    color: black;
}
