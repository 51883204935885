.dl-app-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    border: 0;
    width: 100%;
    z-index: 9999;
}

.dl-component-label {
    font-size: var(--font-size-xs) !important;
    color: var(--black) !important;
    font-weight: var(--font-weight-semibold) !important;
    margin-bottom: 10px !important;
}


.dl-input-textfield input {
    font-size: var(--font-size-sm) !important;
}

.MuiInputBase-input > div:first-child > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-top: 3px;
}


.text-success {
    color: var(--success);
}

.MuiPaper-root {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

/*DLButton css start*/
.btn-loader,
.btn-loader:after {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.btn-loader {
    margin-top: 0;
    padding: 0;
    position: relative;
    animation: load8 0.8s infinite linear;
}


@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*DLButton css start*/


/*DLDatePiker css start*/

.dl-date-picker input{
    padding-top: 7.9px !important;
    padding-bottom: 7.9px  !important;
    color: var(--black);
    font-size: var(--font-size-sm) !important;
}
.dl-date-picker button {
    color: var(--black) !important; /* Replace with your desired color */
}
.dl-date-picker > div >p{
    font-family: var(--font-family-inter);
    margin-top: 4px;
}
/*DLDatePiker css start*/

/*DLDropDown css start*/

.dl-dropdown-textfield-container div{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.dl-dropdown-textfield-xs div{
    min-height: 32px !important;
}

.dl-dropdown-textfield-sm div{
    min-height: 36px !important;
}


.dl-dropdown-textfield-md div{
    min-height: 40px !important;
}

.dl-dropdown-textfield-lg div{
    min-height: 44px !important;
}

.dl-dropdown-textfield-xl div{
    min-height: 48px !important;
}


.MuiInputBase-root > .MuiSvgIcon-root{
    color: var(--black);
}

.MuiPaper-root > ul{
    max-height: 310px;
}
/*DLSearchDropDown css start*/

.dlSearchDropdown-root{
    border: none;
    outline: 1px solid #C5C5C5;
}

.dlSearchDropdown-root.bg-white {
    border-radius: 8px !important;
}

.dlSearchDropdown-root > ul {
    max-height: 100%;
    padding: 0;
}

.dlSearchDropdown-root > ul > li {
    border-bottom: 1px solid #ccc;
    padding: 10px !important;
}

.dlSearchDropdown-root > ul > li:last-child {
    border-bottom: none;
}

.dlSearchDropdown-root > .MuiAutocomplete-noOptions {
    padding: 0;
}

/*DLSearchDropDown css end*/


/*DLDropDown css end*/

/*DLDropDown Button css start*/
.inputBox{
    border: 1px solid var(--gray-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    width:100%;
    padding-left:14px;
    padding-right:14px
}
.MuiListItemText-root > span{
    font-family: var(--font-family-inter) !important;
    font-size: 14px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.MuiListItemButton-root{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.MuiPaper-root{
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
/*DLDropDown Button css end*/

/*DLInputText css start*/
.MuiFormControl-root legend {
    width: 0 !important;
}

.dl-input-textfield > div > input{
    padding: 0 14px  !important
}

.searchable > div{
    border-radius: 24px !important;
}
.searchable > div > input{
    padding: 0 14px  !important
}
/*DLInputText css end*/

/*DLradio button css start*/
.radio-button-css{
    padding: 0 !important;
    padding-right: 4px !important
}
/*DLradio button css end*/

/*DLImage CSS*/
.react-images__positioner{
    Z-index: 1310 !important;
    background-color: rgba(0, 0, 0, 0.5);
}
/*DLImage CSS End*/


/*DLTable CSS*/
.MuiTablePagination-toolbar{
   min-height: 20px !important;
}
.MuiTablePagination-actions{
   min-height: 20px !important;
}
.MuiTablePagination-actions > button{
    padding : 4px !important
}
/*DLTable CSS End*/