.isDocumentupload{
 width: 7px;
 height: 7px;
 margin-right: 4px;
 border: 0.5px;
 border-radius: 1px;
}

.details-text{
 margin-right: 13px;
}

.details-img-container{

 display: flex;
 justify-content: center;
 align-content: center;
}
.details-img{
 height: 20px;
 width: 20px;
}