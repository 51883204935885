#app-header-nav a {
    text-decoration: none;
    color: var(--black-text);
    font-size: var(--font-size-sm);
    line-height: 18px;
}

#app-header-nav a:hover {
    border-bottom: 2px solid var(--black) !important;
}

#app-header-nav a.active {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    border-bottom: 2px solid var(--black-text) !important;
}


.drop-down-menu {
    padding: 8px 0 !important;
    font-size: var(--font-size-md) !important;
}

.drop-down-menu:hover {
    background-color: var(--white) !important;
    text-decoration: underline !important;
}

.profile-img {
    width: 24px !important;
    height: 24px !important;
    object-fit: cover;
    font-size: var(--font-size-xs) !important;
}

.nav-text {
    color: var(--black-text) !important;
    font-size: 16px;
    height: 24px;
}


#app-header-nav-mobile a {
    text-decoration: none;
    color: var(--black-text);
    font-size: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex: 1;
}

#app-header-nav-mobile a.active {
    font-weight: var(--font-weight-semibold);
    height: 100%;
    color: var(--blue-dark);
}

#app-header-nav-mobile a > .horizontal-line {
    height: 2px;
    width: 83%;
    background-color: var(--white) !important;
    border-radius: 20px;
}

#app-header-nav-mobile a.active > .horizontal-line {
    height: 3px;
    width: 100%;
    background-color: var(--black) !important;
    border-radius: 20px;
}
#app-header-nav-mobile div{
    font-size: var(--font-size-xs);
}
.homeTabs> div{
    height: 40px;
}

.appHeader {
    display: flex;
    align-items: center;
}

.menuOptions {
    flex: 0 0 100px; /* Fixed width */
    text-align: center;
    padding: 10px;
}

.clientSearch {
    flex: 1; /* Takes remaining space */
    max-width: 400px; /* Ensures it doesn't grow too much */
    min-width: 200px; /* Ensures it doesn't shrink too much */
    text-align: center;
    padding: 10px;
}


.spacer {
    flex: 1; /* Pushes profileMenu to the right */
    max-width: 300px;
}
@media (max-width: 1200px) {
    .spacer {
        max-width: 100px !important;
    }
}

.profileMenu {
    text-align: center;
    padding: 10px;
}

@media (max-width: 576px) {

    .menuOptions {
        flex: 0 0 100px; /* Fixed width */
        text-align: center;
        padding: 0 !important;
    }
}